import React, { Component, Fragment } from "react";
import { navigate } from "gatsby";
import LayoutEmpty from "../components/layout-empty";
import SEO from "../components/seo";
import CheckoutHeader from "../components/checkout/checkout-header";
import CheckoutFooter from "../components/checkout/checkout-footer";
import Payment from "../components/checkout/payment";
import Account from "../components/checkout/account";
import Success from "../components/checkout/success";
import ShippingAndBilling from "../components/checkout/shipping-and-billing";
import Delivery from "../components/checkout/delivery";
import Stepper from "../components/stepper";
import SpecialOffer from "../components/upsell/special-offer";
import OrderConfirmation from "../components/upsell/order-confirmation";
import OfferExpiresHeader from "../components/upsell/offer-expires-header";
import api from "../api";
import auth from "../lib/auth";
import { clearLocalCart, getCartTotal, getCartTotalOnlySubs, readLocalCart } from "../lib/cart";
import { saveLocalOrder } from "../lib/upsell"
import { deleteLocalCouponCode, isValidCoupon, readLocalCouponCode } from "../lib/coupon";
import update from "immutability-helper";
import "./checkout.scss";
import { isBrowser, isMobile } from "react-device-detect";
import { loadSquareJs, countryContinent } from "../lib/helpers";
import { applyCouponToCartProducts } from "../lib/coupon";
import _get from "lodash.get";
import Rejoiner from "../lib/tracking/rejoiner";
import FacebookPixel from "../lib/tracking/facebook-pixel";
import Justuno from "../lib/tracking/justuno";
import Klayvio from "../lib/tracking/klayvio";
import { formatAmount } from "../lib/helpers";

const STEP_ACCOUNT = "account";
const STEP_SHIPPING_AND_BILLING = "shipping_and_billing";
const STEP_DELIVERY = "delivery";
const STEP_PAYMENT = "payment";
const STEP_SPECIAL_OFFER = "special_offer";
const STEP_ORDER_CONFIRMATION = "order_confirmation";
const STEP_SUCCESS = "success";
const slides = [STEP_ACCOUNT, STEP_SHIPPING_AND_BILLING, STEP_DELIVERY, STEP_PAYMENT, STEP_SUCCESS];

export default class CheckoutPage extends Component {
  constructor (props) {
    super(props);
    const products = props.data.allWcProducts.edges;
    this.state = {
      customer: {shipping:{},billing:{}},
      checkoutLoading: false,
      activeStep: STEP_ACCOUNT,
      coupon: null,
      products: products,
      order: null,
      orderCreating: false,
      orderCreateError: null,
      email: "",
      phone: "",
      first_name: "",
      last_name: "",
      isGuest: false,
      shippingFee: 0,
      routePackageProtection: 0,
      routePackageProtectionSubscription: 0,
      upsell:null,
      offerIndex: 0
    };
  }

  componentDidMount () {
    if (typeof window.ga === "function") {
        window.ga('ec:setAction','checkout', {
          'step': 1
        });
    }
    const cartProducts = readLocalCart();
    if (!cartProducts.length) return navigate("/cart");

    this.init().then(() => {
      console.log("checkout initialized");
      Klayvio.startedCheckout(cartProducts,this.state.coupon);
    });

    if (!window.SqPaymentForm) loadSquareJs();
 
  }

  init = async () => {
    const promises = [];

    // load customer
    if (auth.loggedIn) promises.push(this.loadCustomer());

    // load coupon
    const couponCode = readLocalCouponCode();
    if (couponCode) promises.push(this.loadCoupon(couponCode));

    this.setState({ checkoutLoading: true });
    await Promise.all(promises);
    if (typeof this.state.customer.shipping.country !== "undefined" && this.state.customer.shipping.country !== null && this.state.customer.shipping.country !== "" ){
      this.shippingFeeCallback(this.state.customer.shipping.country);
    }else{
      this.shippingFeeCallback("US");
    }
    this.setState({ checkoutLoading: false });
  };

  loadCustomer = async () => {
    try {
      const customer = await api.account.getMyAccount();
      this.setState({ customer }, () => {
        Klayvio.identify({
          '$email': customer.email,
          '$first_name' : customer.first_name,
          '$last_name' : customer.last_name
        });
        this.gotoStep(STEP_SHIPPING_AND_BILLING);
      });
    } catch (e) {
      if (e.response && e.response.status === 401) {
        alert("Your session is invalid. Please log in again");
        auth.logout();
        return navigate("/");
      } else {
        throw e;
      }
    }
  };

  loadCoupon = async (couponCode) => {
    const { valid, coupon } = await isValidCoupon(couponCode);

    if (valid) {
      this.setState({ coupon });
    } else {
      deleteLocalCouponCode();
    }
  };

  gotoStep = (step) => {
    this.setState({ activeStep: step });
    
    if (typeof window.ga === "function") {
      let cstep = 0;
      switch (step) {
        case "account":
          cstep = 1;
          break;
        case "shipping_and_billing":
          cstep = 2;
          break;
        case "payment":
          cstep = 3;
          break;
        default:
          cstep = 0;
      }
      if (cstep > 0) {
        window.ga('ec:setAction','checkout', {
          'step': cstep
        });
        window.ga('set', 'page', '/checkout/'+step);
        window.ga('send', 'pageview');
      }
    }
    
  };

  accountCallback = (customer,first_name,last_name,phone) => {
    this.setState({ customer:customer, first_name:first_name, last_name:last_name, phone:phone, isGuest:false }, () => {
      if (typeof customer.shipping.country !== "undefined" && customer.shipping.country !== null && customer.shipping.country !== "" ){
        this.shippingFeeCallback(this.state.customer.shipping.country);
      }else{
        this.shippingFeeCallback("US");
      }
      Klayvio.identify({
        '$email': customer.email,
        '$first_name' : first_name,
        '$last_name' : last_name,
        '$phone_number': phone
      });
      this.gotoStep(STEP_SHIPPING_AND_BILLING);
    });
  };

  guestCallback = (email,first_name,last_name,phone) => {
    this.setState({email:email, first_name:first_name, last_name:last_name, phone:phone, isGuest:true}, () => {
      if (typeof this.state.customer.shipping.country !== "undefined" && this.state.customer.shipping.country !== null && this.state.customer.shipping.country !== "" ){
        this.shippingFeeCallback(this.state.customer.shipping.country);
      }else{
        this.shippingFeeCallback("US");
      }
    });
    Klayvio.identify({
      '$email': email,
      '$first_name' : first_name,
      '$last_name' : last_name,
      '$phone_number': phone
    });
    this.gotoStep(STEP_SHIPPING_AND_BILLING);
  }
  
  specialOfferCallback = () => {
    this.gotoStep(STEP_ORDER_CONFIRMATION);
    setTimeout(() => navigate("/thankYou"), 5000);
  };
  changeOffer = (index) => {
    this.setState({offerIndex:index});
    const upsellOffer = this.state.upsell.funnel_offers[index].offer_products[0];
    this.changeOfferHeader(upsellOffer);
  }
  countDownTimerCallback = () => {
    navigate("/thankYou");
  }
  changeOfferHeader = (upsellOffer) => {
    const upsellProduct = this.state.products.find(function(product) {
      return (
        product.node.grouped_products_nodes[0].wordpress_id.toString() ===
          upsellOffer.product_id ||
        product.node.grouped_products_nodes[1].wordpress_id.toString() ===
          upsellOffer.product_id
      );
    }).node;
    var total = parseFloat(upsellOffer.product_offer_price) * parseInt(upsellOffer.product_qty);
    var tax = 0;
    const item = {
      product_id: upsellProduct.wordpress_id,
      quantity: parseInt(upsellOffer.product_qty),
      price: parseFloat(upsellOffer.product_offer_price),
      total: formatAmount(total),
      name: upsellProduct.name,
    };
    if (this.state.customer != null && "CA" === this.state.customer.shipping.state) {
      tax = total * 0.0725;
      total = total + tax;
    }

    this.setState({
      order: {
        line_items: [item],
        total: formatAmount(total),
        total_tax: formatAmount(tax)
      },
      coupon: null,
      shippingFee: 0
    });
  }

  getShippingFee = (country) => {
    let shippingFee = 50;
    const localCart = readLocalCart();
    var orderTotal = applyCouponToCartProducts(localCart, this.state.coupon).total;
    switch (country) {
      case "US":
        if ( orderTotal > 50 ){
          shippingFee = 0
        } else {
          shippingFee = 9
          if(this.state.coupon){
            if(this.state.coupon.free_shipping){
              shippingFee = 0
            }
          }
        }
        break
      case "CA":
        shippingFee = 35
        break
      case "MX":
        shippingFee = 35
        break
      case "CH":
        shippingFee = 55
        break
      default:
        if(countryContinent.hasOwnProperty(country)){
          var continent = countryContinent[country];
          if (continent === "SA"){
            shippingFee = 60
          }else if(continent === "AS"){
            shippingFee = 40
          }else if(continent === "EU"){
            shippingFee = 50
          }else if(continent === "AF"){
            shippingFee = 80
          }
        }
        break
    }
    return shippingFee;
  }

  shippingFeeCallback = (country) => {
    var shippingFee = this.getShippingFee(country);
    this.setState({shippingFee});

  }
  routePackageProtectionCallback = (routePackageProtection) => {
    this.setState({routePackageProtection});
  }
  routePackageProtectionSubscriptionCallback = (routePackageProtectionSubscription) => {
    this.setState({routePackageProtectionSubscription});
  }
  shippingAndBillingCallback = ({ shipping, billing }) => {
    const customer = update(this.state.customer, {
      shipping: { $set: shipping },
      billing: { $set: billing }
    });
    this.setState({ customer });

    this.gotoStep(STEP_PAYMENT);
  };

  deliveryCallback = async option => {
    console.log("delivery option", { option });
    this.gotoStep(STEP_PAYMENT);
  };

  createOrder = async (payment_token) => {
    const localCart = readLocalCart();
    var shippingFee = this.getShippingFee(this.state.customer.shipping.country);
    let shipping_line = shippingFee === 0 ? {method_id: "free_shipping",method_title: "Free Shipping",total: "0"}
    : {method_id: "flat_rate",method_title: "Flat Rate",total: shippingFee.toString()};

    const data = {
      payment_method: "square_credit_card",
      payment_token: payment_token,
      shipping: this.state.customer.shipping,
      billing: this.state.customer.billing,
      line_items: localCart.map(product => ({
        product_id: product.wordpress_id,
        quantity: product.quantity
      })),
      shipping_lines: [shipping_line],
      coupon_lines: this.state.coupon ? [{ code: this.state.coupon.code }] : [],
      fee_lines: this.state.routePackageProtection > 0 ? [{name: 'Route Shipping Protection', total: this.state.routePackageProtection.toString(), tax_status: 'none'}]: [],
      fee_lines_sub: this.state.routePackageProtectionSubscription > 0 ? [{name: 'Route Shipping Protection', total: this.state.routePackageProtectionSubscription.toString(), tax_status: 'none'}]: []
    };

    this.setState({ orderCreating: true });
    if(!this.state.isGuest){
      const { order: paidOrder, subscriptionOrder } = await api.orders.createOrder(data);
      this.setState({ order: paidOrder });
      return {order: paidOrder, subscriptionOrder: subscriptionOrder};
    }else{
      const { order: paidOrder } = await api.orders.createGuestOrder(data);
      this.setState({ order: paidOrder });
      return {order: paidOrder, subscriptionOrder: null};
    }
  };

  paymentCallback = async (paidOrder,subscriptionOrder) => {
    console.log("payment success callback", { paidOrder });

    const localCart = readLocalCart();
    /*
    Rejoiner.sendConversion({
      cart_data: {
        cart_value: parseFloat(paidOrder.total) * 100,
        cart_item_count: localCart.length,
        promo: this.state.coupon ? this.state.coupon.code : null,
        return_url: "https://reasontosmile.com/cart/"
      },
      cart_items: localCart.map(product => ({
        product_id: product.wordpress_id,
        name: product.name,
        price: product.price * 100,
        description: product.description,
        category: product.categories.map(c => c.name),
        item_qty: product.quantity,
        qty_price: product.quantity * product.price * 100,
        product_url: product.url,
        image_url: _get(product, "images[0].src"),
        subscription: product.type === "subscription"
      }))
    });
    */
    FacebookPixel.track("Purchase", {
      value: parseFloat(paidOrder.total),
      currency: paidOrder.currency // USD
    });
    
    if (typeof window.ga === "function") {
      localCart.forEach(product => {
        window.ga('ec:addProduct', {
          'id': product.id,
          'name': product.name,
          'category': product.categories.map(c => c.name).join('/'),
          'variant': product.type,
          'price': product.price,
          'quantity': product.quantity
        });
      })
      if(paidOrder.coupon_lines.length>0){
        window.ga('ec:setAction', 'purchase', {         
          'id': paidOrder.id,                 
          'revenue': paidOrder.total,           
          'tax': paidOrder.total_tax,                    
          'shipping': paidOrder.shipping_total,
          'coupon': paidOrder.coupon_lines[0].code          
        });
        window.ga('send', 'pageview'); 
      }else{
        window.ga('ec:setAction', 'purchase', {         
          'id': paidOrder.id,                         
          'revenue': paidOrder.total,                    
          'tax': paidOrder.total_tax,                          
          'shipping': paidOrder.shipping_total                  
        });
        window.ga('send', 'pageview'); 
      }
    }
    
    const subscribedSum = getCartTotalOnlySubs();
    if (subscribedSum > 0) {
      FacebookPixel.track("Subscribe", {
        value: subscribedSum,
        currency: paidOrder.currency // USD
        // predicted_ltv: "0.00"
      });
    }

    // track Justuno
    this.justunoTrackOrder(paidOrder);

    clearLocalCart();
    deleteLocalCouponCode();

    localStorage.setItem("orderId", paidOrder.id);
    localStorage.setItem("orderAmount", paidOrder.total);
    
    this.gotoStep(STEP_SUCCESS);
    let upsellFunnel = null;
      try{
       upsellFunnel = await api.upsell.getFunnel(paidOrder.id);
      }catch(e){
        console.error(e);
      }
      if(subscriptionOrder != null && upsellFunnel === null){
        try{
          upsellFunnel = await api.upsell.getFunnel(subscriptionOrder.id);
         }catch(e){
          console.error(e);
         }
      }
    this.setState({ upsell: upsellFunnel });
    if(upsellFunnel != null){
      const upsellOffer = this.state.upsell.funnel_offers[this.state.offerIndex].offer_products[0];
      this.changeOfferHeader(upsellOffer);
      this.gotoStep(STEP_SPECIAL_OFFER);
    }else{
      setTimeout(() => navigate("/thankYou"), 2000);
    }
    
  };

  justunoTrackOrder = paidOrder => { // paidOrder - is WooCommerce rest-api object

    const centsForJustuno = m => Math.floor(parseFloat(m) * 100);

    /*
     * Replace the [order...] portions below with the actual order information.
     * You can omit any of the attributes that you don't have values for other then the order id and order total.
     */
    Justuno.juapp("order", paidOrder.id, {
      total: centsForJustuno(paidOrder["total"]),
      // subtotal: what is must here? see console.log(paidOrder)
      tax: centsForJustuno(paidOrder["total_tax"]),
      shipping: centsForJustuno(paidOrder["shipping_total"]),
      currency: paidOrder.currency // USD
    });

    /*
     * You will need to repeat this line of code for each item in the order.
     * Replace the [item ...] portions below with the actual item information in order.
     * You can omit any of the attributes that you don't have values for other then the item id and quantity.
     */
    paidOrder.line_items.forEach(item => {
      Justuno.juapp("orderItem", item.id, {
        name: item.name,
        quantity: item.quantity,
        price: centsForJustuno(item.price),
        // color: "[item color]",
        // size: "[item size]"
      });
    });
    /* end of repeat section */

  };

  buildBackButton = () => {
    switch (this.state.activeStep) {
      case STEP_ACCOUNT:
        return { text: "Continue shopping", onClick: () => navigate("/products/cbd-oil-mighty-mango") };

      case STEP_SHIPPING_AND_BILLING:
        return { text: "Return to account", onClick: () => this.gotoStep(STEP_ACCOUNT) };

      case STEP_DELIVERY:
        return { text: "Return to shipping", onClick: () => this.gotoStep(STEP_SHIPPING_AND_BILLING) };

      case STEP_PAYMENT:
        return { text: "Return to shipping", onClick: () => this.gotoStep(STEP_SHIPPING_AND_BILLING) };

      case STEP_SUCCESS:
        return { text: "Continue shopping", onClick: () => navigate("/products/cbd-oil-mighty-mango") };

      default:
        return { text: "Prev step", onClick: () => {} };
    }
  };

  render () {
    const activeStep = this.state.activeStep;
    const { text: backButtonText, onClick: onBackButtonClick } = this.buildBackButton();
    const backDisabled = activeStep === STEP_SHIPPING_AND_BILLING && auth.loggedIn;
    return (
      <LayoutEmpty>
        <SEO title="Checkout" />
        <section className={`hero checkout-hero${isBrowser ? " is-fullheight" : ""}`}>
          <div className="hero-body">
            <div className="container">

              <div className="columns is-centered checkout-card-columns">
                <div className={`column is-12 is-8-tablet ${activeStep === STEP_SPECIAL_OFFER ? "is-7-desktop upsell" : ( activeStep === STEP_ORDER_CONFIRMATION ? "is-5-desktop upsell" : "is-6-desktop")}`}>

                  <div className="card checkout-card">

                    {activeStep === STEP_SPECIAL_OFFER && isMobile && (
                        <OfferExpiresHeader callback={this.countDownTimerCallback}/>
                      )}
                    {activeStep !== "success" &&
                    <Fragment>
                      <CheckoutHeader order={this.state.order} coupon={this.state.coupon} customer={this.state.customer} shippingFee={this.state.shippingFee} routePackageProtection={this.state.routePackageProtection} step={activeStep}/>
                      {(activeStep !== STEP_SPECIAL_OFFER && activeStep !== STEP_ORDER_CONFIRMATION) &&
                        <div className="border">
                          <Stepper steps={[
                            { title: "Account" },
                            { title: "Shipping" },
                            { title: "Payment" }
                          ]} activeStep={slides.indexOf(activeStep)} />
                        </div>
                      }
                    </Fragment>}

                    <div className="card-content">

                      {activeStep === STEP_ACCOUNT &&
                      <Account
                        className="checkout-step"
                        callback={this.accountCallback}
                        guestCallback={this.guestCallback}
                      />}

                      {activeStep === STEP_SHIPPING_AND_BILLING &&
                      <ShippingAndBilling
                        className="checkout-step"
                        customer={this.state.customer}
                        isGuest={this.state.isGuest}
                        callback={this.shippingAndBillingCallback}
                        email={this.state.email}
                        phone={this.state.phone}
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        shippingFee={this.state.shippingFee}
                        shippingFeeCallback={this.shippingFeeCallback}
                        order={this.state.order}
                        coupon={this.state.coupon}
                        routePackageProtectionCallback={this.routePackageProtectionCallback}
                        routePackageProtectionSubscriptionCallback={this.routePackageProtectionSubscriptionCallback}
                      />}

                      {activeStep === STEP_DELIVERY &&
                      <Delivery
                        className="checkout-step"
                        loading={this.state.orderCreating}
                        callback={this.deliveryCallback}
                      />}

                      {activeStep === STEP_PAYMENT &&
                      <Payment
                        className="checkout-step"
                        customer={this.state.customer}
                        createOrder={this.createOrder}
                        callback={this.paymentCallback}
                      />}
                      {activeStep === STEP_SPECIAL_OFFER && (
                        <SpecialOffer
                          upsellFunnel={this.state.upsell}
                          offerIndex={this.state.offerIndex} 
                          products={this.state.products}
                          customer={this.state.customer}
                          callback={this.specialOfferCallback}
                          changeOffer={this.changeOffer}
                        />
                      )}
                      {activeStep === STEP_ORDER_CONFIRMATION && (
                        <OrderConfirmation />
                      )}
                      {activeStep === STEP_SUCCESS &&
                      <Success className="checkout-step"
                      />}

                    </div>

                    {(activeStep !== "success" && activeStep !== STEP_SPECIAL_OFFER && activeStep !== STEP_ORDER_CONFIRMATION) &&
                    <CheckoutFooter
                      backDisabled={backDisabled}
                      backText={backButtonText}
                      onBackClick={onBackButtonClick} />}


                    {this.state.checkoutLoading &&
                    <section className="section checkout-loading">
                      <h4><i className="fas fa-spinner fa-spin has-text-grey-light" /> Checkout Loading...</h4>
                    </section>}

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </LayoutEmpty>
    );
  }
}
export const pageQuery = graphql`
  query {
    allWcProducts(
      filter: {
        catalog_visibility: { eq: "visible" }
        status: { eq: "publish" }
        tags: { elemMatch: { name: { eq: "CBD" } } }
      }
    ) {
      edges {
        node {
          id
          type
          wordpress_id
          slug
          name
          price
          images {
            id
            alt
            src
          }
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          description
          grouped_products_nodes {
            id
            wordpress_id
            name
            price
            regular_price
            type
            slug
            images {
              id
              alt
              src
            }
            categories {
              wordpress_id
              name
            }
          }
        }
      }
    }
  }
`;