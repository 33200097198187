import React from "react";

export default function Success ({ className }) {
  return (
    <div className={`checkout-success success-feedback${className ? ` ${className}` : ""}`}>
      <img src={require("../../images/done.svg")} alt="" className="success-feedback--check" />
      <h3>Payment success.<br />Hooray!</h3>
      <br />
      <p>Hold on, you'll be directed in a few seconds.</p>
    </div>
  );
}
