import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { readLocalCart } from "../../lib/cart";
import { formatAmount } from "../../lib/helpers";
import { applyCouponToCartProducts } from "../../lib/coupon";

export default function OrderDetails ({ order, coupon, customer, shippingFee, routePackageProtection }) {

  const orderRows = order ? order.line_items.map(item => ({
    product_id: item.product_id,
    quantity: item.quantity,
    price: parseFloat(item.price),
    total: parseFloat(item.total),
    name: item.name
  })) : readLocalCart().map(item => ({
    product_id: item.wordpress_id,
    quantity: item.quantity,
    price: parseFloat(item.price),
    total: item.price * item.quantity,
    name: item.name
  }));

  let subtotal, discount_sum, total;
  let tax = 0;
  if (!order) {
    const result = applyCouponToCartProducts(readLocalCart(), coupon);
    subtotal = result.subtotal;
    discount_sum = result.discount_sum;
    total = result.total;
    if(customer != null && "CA" === customer.shipping.state){
      tax = total * 0.0725;
    }
    if(tax > 0){
      total = total + tax;
    }
    if(shippingFee > 0){
      total = total + shippingFee;
    }
    if(routePackageProtection > 0){
      total = total + routePackageProtection;
    }
  }

  return (
    <div className="order-details">
      <table className="table is-narrow order-details-table">
        <tbody>
          {orderRows.map((orderRow, index) => (
            <tr key={index}>
              <th><p>{orderRow.name}</p></th>
              <td><p>{orderRow.quantity} × ${formatAmount(orderRow.price)}</p></td>
            </tr>
          ))}
        </tbody>
        {!order ?
          <tfoot>
            <tr>
              <th><p>Subtotal</p></th>
              <td><p>${formatAmount(subtotal)}</p></td>
            </tr>

            {discount_sum > 0 ? <tr>
              <th><p>Discount total</p></th>
              <td><p>${formatAmount(discount_sum)}</p></td>
            </tr> : <Fragment />}
            {shippingFee > 0 ? <tr>
              <th><p>Shipping</p></th>
              <td><p>${formatAmount(shippingFee)}</p></td>
            </tr> : <Fragment />
            }
            {tax > 0 ? <tr>
              <th><p>Tax</p></th>
              <td><p>${formatAmount(tax)}</p></td>
            </tr> : false}
            {routePackageProtection > 0 ?<tr>
              <th><p>ROUTE PACKAGE PROTECTION</p></th>
              <td><p>${formatAmount(routePackageProtection)}</p></td>
            </tr> : false} 
            <tr>
              <th><p className="has-text-weight-bold">Total due now</p></th>
              <td><p className="has-text-weight-bold">${formatAmount(total)}</p></td>
            </tr>

          </tfoot>
          :
          <tfoot>
            <tr>
              <th><p>Subtotal</p></th>
              <td>
                <p>${formatAmount(order.line_items.reduce((sum, item) => sum + item.price * item.quantity, 0))}</p>
              </td>
            </tr>
            {/* <tr>
              <th><p>Shipping:</p></th>
              <td><p>${order["shipping_total"]}</p></td>
            </tr> */}
            {parseFloat(order["discount_total"]) > 0 ?
              <tr>
                <th><p>Discount total:</p></th>
                <td><p>${order["discount_total"]}</p></td>
              </tr> : <Fragment />}
            <tr>
              <th><p>Tax</p></th>
              <td><p>${order["total_tax"]}</p></td>
            </tr>
            <tr>
              <th><p className="has-text-weight-bold">Total due now</p></th>
              <td><p className="has-text-weight-bold">${order["total"]}</p></td>
            </tr>

          </tfoot>
        }
      </table>
    </div>
  );
}

OrderDetails.propTypes = {
  order: PropTypes.any,
  coupon: PropTypes.any
};
