import React, { Component, Fragment } from "react";
import TextField from "../form/textField.js";
import PropTypes from "prop-types";
import api from "../../api";
import auth from "../../lib/auth";
import _get from "lodash.get";
import { readLocalCart } from "../../lib/cart";
import { validatePhone } from "../../lib/helpers";

export default class Account extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isSignup: true,

      // values
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",

      // errors
      error_first_name: "",
      error_last_name: "",
      error_email: "",
      error_password: "",
      error_registration: "",
      error_phone: "",

      submitting: false,
      products: []
    };
  }

  resetErrors = () => {
    this.setState({
      error_first_name: "",
      error_last_name: "",
      error_email: "",
      error_password: "",
      error_registration: "",
      error_phone: ""
    });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });

    // reset error
    const error_key = `error_${e.target.name}`;
    if (this.state[error_key]) {
      this.setState({ [error_key]: "" });
    }
    if (e.target.name === "email" && this.state.isSignup) {
      this.setState({ error_registration: "" });
    }
  };

  onSubmit = async e => {
    e.preventDefault();
    this.resetErrors();

    let success, customer;

    try {
      this.setState({ submitting: true });

      if (this.state.isSignup) {
        let phone = validatePhone(this.state.phone);
        if(phone){
          this.setState({phone: phone});
          await auth.registerAndLogin({
            email: this.state.email,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            username: this.state.email,
            password: this.state.password
          });
          customer = await api.account.getMyAccount();
          success = true;
        }else{
          this.setState({ error_phone: "Invalid phone number" });
        }
      } else {
        await auth.login({
          username: this.state.email,
          password: this.state.password
        });
        customer = await api.account.getMyAccount();
        success = true;
      }

    } catch (e) {
      this.handleError(e);
    } finally {
      this.setState({ submitting: false });
    }

    if (success && customer) {
      this.props.callback(customer,this.state.first_name, this.state.last_name, this.state.phone);
    }
  };

  handleError = e => {

    if (_get(e, "response.status") === 400 && _get(e, "response.data.code") === "invalid_email") {
      this.setState({ error_email: "Invalid email address" });
      return;
    }

    if (_get(e, "response.status") === 400 && _get(e, "response.data.code") === "incorrect_password") {
      this.setState({ error_password: "The password is incorrect" });
      return;
    }

    if (_get(e, "response.status") === 400 && _get(e, "response.data.code") === "registration-error-email-exists") {
      this.setState({ error_registration: _get(e, "response.data.message") });
      return;
    }

    throw e;
  };

  toggleForm = () => {
    this.resetErrors();
    this.setState({ isSignup: !this.state.isSignup });
  };

  handleGuestCheckout = () => {
    this.props.guestCallback(this.state.email, this.state.first_name, this.state.last_name, this.state.phone);
  }

  componentDidMount = () =>{
    const cartProducts = readLocalCart();
    this.setState({ products: cartProducts });
  }


  render () {

    return (
      <div className={`container spacing${this.props.className ? ` ${this.props.className}` : ""}`}>

        {this.state.isSignup ?
          <div>
            <h4>Create an account for a fast & secure checkout-today, and whenever we see you next</h4>
            <p>Already have an account? <span className="has-text-primary" onClick={this.toggleForm}>LOG IN</span>
            </p>
          </div>
          :
          <div>
            <p>Nice to see you again. To proceed, please log in using your e-mail and password.</p>
            <p>Don't have an account? <span className="has-text-primary" onClick={this.toggleForm}>SIGN UP</span>
            </p>
          </div>
        }
        <br />
        <form onSubmit={this.onSubmit} className="form">


          {
            this.state.isSignup &&
            <Fragment>
              {this.state.error_registration && <p className="has-text-danger mb-3">{this.state.error_registration}</p>}
              <div className="field is-horizontal">
                <div className="field-body">
                  <TextField label="First Name" name="first_name" type="text" onChange={this.handleChange} value={this.state.first_name} />
                  <TextField label="Last Name" name="last_name" type="text" onChange={this.handleChange} value={this.state.last_name} />
                </div>
              </div>
            </Fragment>
          }

          <div className="field is-horizontal">
            <div className="field-body">
              <TextField label="Email" name="email" error={this.state.error_email} type="email" onChange={this.handleChange} value={this.state.email} />
              {
                this.state.isSignup ?
                <TextField label="Phone" name="phone" error={this.state.error_phone} value={this.state.phone} onChange={this.handleChange}/>
                :
                <TextField label="Password" minLength={this.state.isSignup ? 6 : undefined}
                  name="password" error={this.state.error_password} type="password" onChange={this.handleChange} value={this.state.password} />
              }
            </div>
          </div>
          { this.state.isSignup &&
          <div className="field is-horizontal">
            <div className="field-body">
              <TextField label="Password" minLength={this.state.isSignup ? 6 : undefined}
                name="password" error={this.state.error_password} type="password" onChange={this.handleChange} value={this.state.password} />
            </div>
          </div>
          }
          <br className="is-hidden-mobile" />
          <button className={`button is-medium is-primary is-fullwidth${this.state.submitting ? " is-loading" : ""}`} type="submit">
            Continue
          </button>
          {this.state.products.filter(p => p.type === "subscription").length === 0 ?
            <button className="button is-medium is-primary is-fullwidth guest-checkout-button" type="submit" onClick={this.handleGuestCheckout}>
              Checkout as a guest
            </button>
          :
          false}
        </form>

      </div>
    );
  }

}

Account.propTypes = {
  className: PropTypes.string,
  callback: PropTypes.func.isRequired
};
