import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Delivery extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedOption: "free_shipping" // flat-rate
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.callback(this.state.selectedOption);
  };

  handleSelectOption = (option) => {
    this.setState({
      selectedOption: option
    });
  };

  render () {
    return (
      <div className={`container spacing${this.props.className ? ` ${this.props.className}` : ""}`}>
        <div className="columns">
          <div className="column is-centered">
            <form className="form" onSubmit={this.handleSubmit}>
              <a className={this.state.selectedOption === "free_shipping" ? "has-text-primary has-text-weight-bold" : "has-text-black"} onClick={() => this.handleSelectOption("free_shipping")}>FREE SHIPPING | FREE (1-3 days)</a>
              {/*
                ONLY ONE SHIPPING OPTION FOR NOW
              <a className={this.state.selectedOption === "second-option" ? "has-text-primary has-text-weight-bold" : "has-text-black"} onClick={() => this.handleSelectOption("second-option")}>FLAT RATE SHIPPING | $10</a>
              */}
              <br /><br />
              <button className={`button is-medium is-primary is-fullwidth${this.props.loading ? " is-loading" : ""}`} type="submit">Continue</button>
            </form>
          </div>
        </div>
      </div>
    );
  }

}

Delivery.propTypes = {
  className: PropTypes.string,
  callback: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
