import React, { Component } from "react";
import api from "../../api";
import Rating from "react-rating";
import "./special-offer.scss";
import yotpo from "../../lib/yotpo";
import _get from "lodash.get";
import { navigate } from "gatsby";
import { formatAmount } from "../../lib/helpers";
import FacebookPixel from "../../lib/tracking/facebook-pixel";
const reviews_per_page = 10;

const parseReviewsData = reviewsData => ({
  reviewsSummary: {
    total_reviews: _get(reviewsData, "bottomline.total_review", 0) || 0,
    starred_5: _get(reviewsData, "bottomline.star_distribution.5", 0) || 0,
    starred_4: _get(reviewsData, "bottomline.star_distribution.4", 0) || 0,
    starred_3: _get(reviewsData, "bottomline.star_distribution.3", 0) || 0,
    starred_2: _get(reviewsData, "bottomline.star_distribution.2", 0) || 0,
    starred_1: _get(reviewsData, "bottomline.star_distribution.1", 0) || 0,
    avg_rating: _get(reviewsData, "bottomline.average_score", 0) || 0,
  },
  reviews: _get(reviewsData, "reviews", []),
  reviewsPagination: _get(reviewsData, "pagination"),
});
const a = [
  "",
  "one ",
  "two ",
  "three ",
  "four ",
  "five ",
  "six ",
  "seven ",
  "eight ",
  "nine ",
  "ten ",
  "eleven ",
  "twelve ",
  "thirteen ",
  "fourteen ",
  "fifteen ",
  "sixteen ",
  "seventeen ",
  "eighteen ",
  "nineteen ",
];
const b = [
  "",
  "",
  "twenty",
  "thirty",
  "forty",
  "fifty",
  "sixty",
  "seventy",
  "eighty",
  "ninety",
];
const inWords = num => {
  if ((num = num.toString()).length > 9) return 'overflow';
  var n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return; var str = '';
  str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
  str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
  str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
  str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
  str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'additional ' : '';
  return str;
};
export default class SpecialOffer extends Component {
  constructor(props) {
    super(props);

    const parsedReviewsData = parseReviewsData(this.props.reviewsData);

    this.state = {
      reviewsSummary: parsedReviewsData.reviewsSummary,
      // loading indicator
      submitting: false,
      cancelOfferSubmitting: false
    };
  }

  componentDidMount = () => {
    console.log(this.props);
    const upsellOffer = this.props.upsellFunnel.funnel_offers[
      this.props.offerIndex
    ].offer_products[0];
    const upsellProduct = this.props.products.find(function(product) {
      return (
        product.node.grouped_products_nodes[0].wordpress_id.toString() ===
          upsellOffer.product_id ||
        product.node.grouped_products_nodes[1].wordpress_id.toString() ===
          upsellOffer.product_id
      );
    }).node;
    yotpo
      .getGroupedProductReviews(upsellProduct, {
        per_page: reviews_per_page,
        page: 1,
      })
      .then(reviewsData => {
        const parsedReviewsData = parseReviewsData(reviewsData);
        this.setState({
          reviewsSummary: parsedReviewsData.reviewsSummary,
          reviews: parsedReviewsData.reviews,
          reviewsPagination: parsedReviewsData.reviewsPagination,
        });
      });
  };
  cancelOffer = () => {
    if (
      this.props.upsellFunnel.funnel_offers.length >
      this.props.offerIndex + 1
    ) {
      this.props.changeOffer(this.props.offerIndex + 1);
    } else {
      this.setState({ cancelOfferSubmitting: true });
      api.upsell.processOffer({
        order_key: this.props.upsellFunnel.order_key,
        offer_id: this.props.upsellFunnel.funnel_offers[this.props.offerIndex]
          .offer_id,
        funnel_token: this.props.upsellFunnel.funnel_token,
        offer_action: "no",
      });
      this.setState({ cancelOfferSubmitting: false });
      setTimeout(() => navigate("/thankYou"), 5000);
    }
  };

  processOffer = async () => {
    this.setState({ submitting: true });
    const response = await api.upsell.processOffer({
      order_key: this.props.upsellFunnel.order_key,
      offer_id: this.props.upsellFunnel.funnel_offers[this.props.offerIndex]
        .offer_id,
      funnel_token: this.props.upsellFunnel.funnel_token,
      offer_action: "yes",
    });
    this.setState({ submitting: false });
    if (response.offer_processed || !response.offer_processed) {
      if(response.offer_processed) {
        FacebookPixel.track("Purchase", {
          value: parseFloat(this.props.upsellFunnel.funnel_offers[this.props.offerIndex].offer_products[0].product_offer_price),
          currency: 'USD' // USD
        });
        localStorage.setItem("upsellOrderAmount", this.props.upsellFunnel.funnel_offers[this.props.offerIndex].offer_products[0].product_offer_price);
      }
      this.props.callback();
    }
  };
  render() {
    const { upsellFunnel, products, customer, offerIndex } = this.props;
    const { avg_rating = 0, total_reviews = 0 } = this.state.reviewsSummary;
    const upsellOffer =
      upsellFunnel.funnel_offers[this.props.offerIndex].offer_products[0];
    const upsellProduct = products.find(function(product) {
      return (
        product.node.grouped_products_nodes[0].wordpress_id.toString() ===
          upsellOffer.product_id ||
        product.node.grouped_products_nodes[1].wordpress_id.toString() ===
          upsellOffer.product_id
      );
    }).node;
    let upsellGroundedProduct =
      upsellProduct.grouped_products_nodes[0].wordpress_id.toString() ===
      upsellOffer.product_id
        ? upsellProduct.grouped_products_nodes[0]
        : upsellProduct.grouped_products_nodes[1];

    const imageSrc = _get(upsellProduct, "images[0].src");
    const imageAlt = _get(upsellProduct, "images[0].alt") || upsellProduct.name;
    let titleName = 
    upsellProduct.slug.indexOf("vegan-gummies") !== -1
        ? "CBD Vegan Gummies"
        : "Oil Drops";
    let name =
      upsellProduct.slug.indexOf("vegan-gummies") !== -1
        ? "CBD Vegan Gummies"
        : "CBD Oil";

    return (
      <div className="container special-offer">
        <div>
          {offerIndex === 0 ?
          <div className="offer-desp">
            <h4>
              {customer.shipping.first_name.replace(/\s/g, '')}, wait a moment!
            </h4>
            <p>
              Right now, we’re offering <b>{inWords(upsellOffer.product_qty)} {titleName}</b>
              at&nbsp;<b>
              {(
                (1 -
                  parseFloat(upsellOffer.product_offer_price) / parseFloat(upsellOffer.product_price)) *
                100
              ).toFixed(0)}
              % off.</b>
            </p>
          </div>
          :
          <div className="offer-desp">
            <h4>We have one last offer.. </h4>
            <p>
            Would you like to add <b>{inWords(upsellOffer.product_qty)} {titleName}</b>
            to your order at&nbsp;<b>
              {(
                (1 -
                  parseFloat(upsellOffer.product_offer_price) / parseFloat(upsellOffer.product_price)) *
                100
              ).toFixed(0)}
              % off?</b>
            </p>
          </div>
          }
          <div className="columns offer-product">
            <div className="column is-6 offer-product-image">
              <div className="columns">
                <div className="column">
                  <div className="discount-badge">
                    <h2>
                      {(
                        (1 -
                          parseFloat(upsellOffer.product_offer_price) /
                          parseFloat(upsellOffer.product_price)) *
                        100
                      ).toFixed(0)}
                      % off
                    </h2>
                  </div>
                  {imageSrc && <img src={imageSrc} alt={imageAlt} />}
                </div>
              </div>
            </div>
            <div className="column is-6 offer-product-info">
              <h2> {name}</h2>

              <p className="product-prop">
                {upsellProduct.slug.indexOf("vegan-gummies") !== -1
                  ? "Passion Fruit"
                  : upsellProduct.name}{" "}
                | {upsellProduct.acf.effect} |{" "}
                {(upsellProduct.slug.indexOf("vegan-gummies") !== -1 || upsellProduct.slug.indexOf("face-cream") !== -1)
                  ? "300MG CBD"
                  : "500MG CBD"}
              </p>
              <div className="product-review">
                <div
                  className="is-inline-block cursor-pointer"
                  aria-label="Show reviews">
                  <div className="columns is-mobile is-vcentered is-gapless">
                    <div className="column is-narrow">
                      <Rating
                        className="star-rating readonly"
                        readonly={true}
                        initialRating={avg_rating}
                        emptySymbol={
                          <label className="star-rating__star">☆</label>
                        }
                        fullSymbol={
                          <label className="star-rating__star is-selected">
                            ★
                          </label>
                        }
                      />
                      &nbsp;
                    </div>
                    <div className="column">
                      <p className="is-inline-desktop">
                        {avg_rating.toFixed(1)} ({total_reviews} Reviews)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="product-price">
              {upsellOffer.product_qty>1?"Each just":"Just"}&nbsp;
                {upsellGroundedProduct.type === "subscription" ? (
                  <span>${formatAmount(parseFloat(upsellOffer.product_offer_price))}</span>
                ) : (
                  <span>${formatAmount(parseFloat(upsellOffer.product_offer_price))}</span>
                )}
                &nbsp;<span>(<s>${formatAmount(parseFloat(upsellOffer.product_price))}</s>)</span>
              </p>
              {offerIndex === 0 ?
              <p className="upsell-note">
                Your entire order will be <b>shipped for free</b> and <b>all products</b> will arrive at your doorstep, together.
              </p>
              :
              <p className="upsell-note">
                Your entire order will be <b>shipped for free</b> and <b>all products</b> will arrive at your doorstep, together.
              </p>
              }
            </div>
          </div>
          <div className="columns">
            <div className="column is-6">
              <button
                className={`button is-secondary is-fullwidth${
                  this.state.cancelOfferSubmitting ? " is-loading" : ""
                }`}
                disabled={this.state.cancelOfferSubmitting}
                onClick={this.cancelOffer}>
                NO, THANK YOU
              </button>
            </div>
            <div className="column is-6">
              <button
                className={`button is-primary is-fullwidth${
                  this.state.submitting ? " is-loading" : ""
                }`}
                disabled={this.state.submitting}
                onClick={this.processOffer}>
                YES! ADD THIS TO MY ORDER!
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
