import React, { Component } from "react";
import { Link } from "gatsby";

export default class OrderConfirmation extends Component {
  render() {
    return (
      <div className="success-upsell-order" style={{ textAlign: "center" }}>
        <img
          src={require("../../images/done.svg")}
          alt=""
          className="success-upsell-order--check"
        />
        <h3>Congrats! Your item has been successfully added to the order.</h3>
        <p>You’ll be redirected in a few seconds.</p>
        <div>
          <Link to="/thankYou" className="go-to-account">
            <span>I don’t want to wait</span>
            <i className="fal fa-chevron-right"></i>
          </Link>
        </div>
      </div>
    );
  }
}
