import React, { Component } from "react";
import "./payment.scss";
import _get from "lodash.get";
import PropTypes from "prop-types";
import FacebookPixel from "../../lib/tracking/facebook-pixel";

export default class Payment extends Component {
  constructor (props) {
    super(props);

    const customer = this.props.customer;

    this.state = {
      paymentMethod: customer.card ? "existing" : "new",
      squareErrors: null,
      error: null,
      isPaymentFormLoaded: false,

      // for process payment use one of then
      card_id: customer.card ? customer.card.id : null,
      card_nonce: null,

      // loading indicator
      submitting: false,
      over18: false
    };
    this.handleOver18Change = this.handleOver18Change.bind(this);
  }

  componentDidMount () {
    this.createSqPaymentForm();
  }

  setPaymentMethod = method => {
    this.setState({ paymentMethod: method });
  };

  handleOver18Change = e => {
    this.setState({ over18: e.target.checked })
    if(e.target.checked){
      this.setState({ error: null });
    }
  };

  createSqPaymentForm = () => {

    // Create and initialize a payment form object
    const paymentForm = new window.SqPaymentForm({
      // Initialize the payment form elements
      applicationId: process.env.GATSBY_SQUARE_APPLICATION_ID,
      inputClass: "input",
      inputErrorClass: "is-danger",
      autoBuild: false,
      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [
        {
          fontSize: "16px",
          lineHeight: "24px",
          placeholderColor: "#9e9c9a",
          backgroundColor: "transparent"
        }
      ],
      // Initialize the credit card placeholders
      cardNumber: {
        elementId: "sq-card-number",
        placeholder: "Card Number"
      },
      cvv: {
        elementId: "sq-cvv",
        placeholder: "CVV"
      },
      expirationDate: {
        elementId: "sq-expiration-date",
        placeholder: "MM/YY"
      },
      postalCode: {
        elementId: "sq-postal-code",
        placeholder: "Postal"
      },
      // SqPaymentForm callback functions
      callbacks: {
        paymentFormLoaded: () => {
          this.setState({ isPaymentFormLoaded: true });
        },
        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: async (errors, nonce, cardData) => {
          this.setState({ squareErrors: errors });
          if (errors) return this.setState({ submitting: false });

          console.log(`The generated nonce is:`, { nonce });
          FacebookPixel.track("AddPaymentInfo");
          this.setState({ card_nonce: nonce }, this.processPayment);
        }
      }
    });
    paymentForm.build();
    this.paymentForm = paymentForm;
  };

  onPayClick = async e => {
    e.preventDefault();
    if(!this.state.over18){
      this.setState({ error: "Please check the box to confirm you are over the age of 18." });
    }else{
      this.setState({ submitting: true });

      if (this.state.paymentMethod === "new") {
        this.paymentForm.requestCardNonce();
      } else {
        return this.processPayment();
      }
    }
  };

  getPaymentToken = () => {
    if (this.state.paymentMethod === "existing") {
      return this.state.card_id;
    } else {
      return this.state.card_nonce;
    }
  };

  processPayment = async () => {
    this.setState({ error: null });
    try {
      const { order: paidOrder, subscriptionOrder} = await this.props.createOrder(this.getPaymentToken());
      this.setState({ submitting: false }, () => {
        this.props.callback && this.props.callback(paidOrder,subscriptionOrder);
      });
    } catch (e) {
      this.setState({ submitting: false });
      this.handlePaymentError(e);
    }
  };

  handlePaymentError = e => {
    let errorHandled = false;

    // handle 422 error
    if (!errorHandled) {
      if (e.response.status === 422) {
        const errors = e.response.data.errors || {};
        const errorsKeys = Object.keys(errors);
        if (errorsKeys.length) {
          const firstErrorKey = errorsKeys[0];
          const firstErrorArr = errors[firstErrorKey] || [];
          const error = firstErrorArr[0];
          if (error) {
            this.setState({ error });
            errorHandled = true;
          }
        }
      }
    }

    // handle 400, 404 error
    if (!errorHandled) {
      if (e.response.status === 400 || e.response.status === 404) {
        const error = _get(e.response.data, "errors[0].detail");
        if (error) {
          this.setState({ error });
          errorHandled = true;
        }
      }
    }

    if (!errorHandled && e.response) {
      this.setState({ error: JSON.stringify(e.response.data) });
      errorHandled = true;
    }

    if (!errorHandled) {
      throw e;
    }
  };

  render () {
    const card = this.props.customer.card;

    // square errors
    const getSquareError = field => {
      const error = (this.state.squareErrors || []).find(e => e.field === field);
      return _get(error, "message", null);
    };
    const cardNumberError = getSquareError("cardNumber");
    const cvvError = getSquareError("cvv");
    const expirationDateError = getSquareError("expirationDate");
    const postalCodeError = getSquareError("postalCode");

    return (
      <div className={`container spacing payment${this.props.className ? ` ${this.props.className}` : ""}`}>

        <div className={`payment-options${card ? " selectable" : ""}`}>

          {/*card && <p className="mb-3" style={{ paddingLeft: 30 }}>Select payment method:</p>*/}

          {card &&
          <div className={`payment-option payment-option-existing${this.state.paymentMethod === "existing" ? " selected" : ""}`}>
            <div className="payment-option--title" onClick={() => this.setPaymentMethod("existing")}>
              <p>
                {card["cardholder_name"]}<br />
                {card["card_brand"]} •••• •••• •••• {card["last_4"]}&nbsp;&nbsp;&nbsp;EXP: {card["exp_month"]}/{card["exp_year"]}
              </p>
            </div>
          </div>
          }

          <div className={`payment-option payment-option-new${this.state.paymentMethod === "new" ? " selected" : ""}`}>
            <div className="payment-option--title" onClick={() => this.setPaymentMethod("new")}>NEW PAYMENT METHOD</div>
            <div className="payment-option--content" style={{ display: this.state.paymentMethod === "new" ? "block" : "none" }}>
              <div className="form">

                <div className="field">
                  <div id="sq-card-number" />
                  {cardNumberError && <p className="help is-danger">{cardNumberError}</p>}
                </div>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field">
                      <div id="sq-expiration-date" />
                      {expirationDateError && <p className="help is-danger">{expirationDateError}</p>}
                    </div>
                    <div className="field">
                      <div id="sq-cvv" />
                      {cvvError && <p className="help is-danger">{cvvError}</p>}
                    </div>
                    <div className="field">
                      <div id="sq-postal-code" />
                      {postalCodeError && <p className="help is-danger">{postalCodeError}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field">
            <div className="control">
              <label className="checkbox is-size-6 m-0">
                <input type="checkbox" checked={this.state.over18} onChange={this.handleOver18Change}/>
                By purchasing from us, you are confirming that you are over the age of 18.
              </label>
            </div>
          </div>
        {this.state.error && <div className="has-text-danger mb-3 has-text-centered">{this.state.error}</div>}


        <button id="sq-creditcard"
          className={`button-credit-card button is-medium is-primary is-fullwidth${this.state.submitting ? " is-loading" : ""}`}
          disabled={!this.state.isPaymentFormLoaded || this.state.submitting}
          onClick={this.onPayClick}>Pay
        </button>
      </div>
    );
  }

}

Payment.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  createOrder: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};
