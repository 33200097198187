import React from "react";

export default function CheckoutFooter (
  {
    backText = "BACK",
    backDisabled = false,
    onBackClick = () => {
      console.log("on back click");
    }
  }
) {
  return (
    <footer id="checkout-form-footer" className="card-footer is-borderless is-block">
      <div className="columns is-vcentered is-gapless is-mobile">
        <div className="column">
          <span className={`checkout-back${backDisabled ? " disabled" : ""}`} onClick={onBackClick}>
            <img className="icon back" src={require("../../images/back.svg")} alt="" />{backText}
          </span>
        </div>
        <div className="column is-narrow">
          <span className="checkout-secure">
            Secure Checkout<img className="icon lock" src={require("../../images/checkout/lock.svg")} alt="" />
          </span>
        </div>
      </div>
    </footer>
  );
}
