import React, { Component, Fragment } from "react";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import { readLocalCart, getCartQuantity } from "../../lib/cart";
import { applyCouponToCartProducts } from "../../lib/coupon";
import { applyTaxToTotal } from "../../lib/tax";
import { formatAmount } from "../../lib/helpers";
import OrderDetails from "./order-details";
import { isBrowser } from "react-device-detect";
import OfferExpiresHeader from "../../components/upsell/offer-expires-header";

export default class CheckoutHeader extends Component {

  constructor (props) {
    super(props);

    this.state = {
      showOrderDetails: false
    };
  }

  onBagClick = () => {
    console.log("on bag click");
    this.setState({ showOrderDetails: !this.state.showOrderDetails });
  };
  countDownTimerCallback = () => {
    navigate("/thankYou");
  }

  render () {
    let total, itemsCount;
    if (this.props.order) {
      total = formatAmount(parseFloat(this.props.order.total));
      itemsCount = this.props.order.line_items.reduce((sum, item) => sum + item.quantity, 0);
    } else {
      total = formatAmount(applyTaxToTotal(applyCouponToCartProducts(readLocalCart(), this.props.coupon).total,this.props.customer)+this.props.shippingFee+this.props.routePackageProtection);
      itemsCount = getCartQuantity();
    }

    return (
      <Fragment>
        <header className="card-header is-shadowless is-block">
          <div className="columns is-vcentered is-gapless is-mobile">
            <div className="column">
              <Link to="/"><img src={require("../../images/smile_logo_yellow.svg")} width="112" height="28" alt="" /></Link>
            </div>
            {
              isBrowser && this.props.step === "special_offer" && 
              <div className="column">
              <OfferExpiresHeader callback={this.countDownTimerCallback}/>
              </div>
            }
            <div className="column">
              <span className="bag-link" onClick={this.onBagClick}>
                <div className="icon-cart">
                  <img src={require("../../images/bag-alternate.svg")} alt="" />
                  <p className="icon-cart-count">{itemsCount}</p>
                </div>
                <span>${total}</span>
                <div className={`chevron${this.state.showOrderDetails ? " is-expanded" : ""}`} />
              </span>
            </div>
          </div>
        </header>
        {this.state.showOrderDetails && <OrderDetails order={this.props.order} coupon={this.props.coupon} customer={this.props.customer} shippingFee={this.props.shippingFee} routePackageProtection={this.props.routePackageProtection}/>}
      </Fragment>
    );
  }
}

CheckoutHeader.propTypes = {
  order: PropTypes.any,
  coupon: PropTypes.any
};
