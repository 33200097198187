import React, { Component } from "react";
import CountDownTimer from "./countdown-timer";

export default class OfferExpiresHeader extends Component {
  render() {
    return (
      <div className="offer-expires-header">
        <div className="columns">
          <div className="column count-down-timer">
            <p>offer expires in:</p>
            <CountDownTimer callback={this.props.callback} />
          </div>
        </div>
      </div>
    );
  }
}
