import React, { Component } from "react";
import update from "immutability-helper";
import api from "../../api";
import _merge from "lodash.merge";
import diff from "deep-diff";
import TextField from "../form/textField";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { validatePhone, loadRouteJs } from "../../lib/helpers";
import { readLocalCart } from "../../lib/cart";
import { applyCouponToCartProducts } from "../../lib/coupon";
import { applyTaxToTotal } from "../../lib/tax";
import PropTypes from "prop-types";
import { formatAmount } from "../../lib/helpers";

const defaultCountry = "US";

const emptyShipping = () => ({
  first_name: "",
  last_name: "",
  company: "",
  address_1: "",
  address_2: "",
  city: "",
  postcode: "",
  country: defaultCountry,
  state: ""
});

const emptyBilling = () => ({
  first_name: "",
  last_name: "",
  company: "",
  address_1: "",
  address_2: "",
  city: "",
  postcode: "",
  country: defaultCountry,
  state: "",
  email: "",
  phone: ""
});

const isDiffAddresses = (addressA, addressB) => {
  const differences = diff(addressA, addressB);
  return (typeof differences !== "undefined") && differences && differences.filter(d => d.kind === "E").length > 0;
  
};

export default class ShippingAndBilling extends Component {

  constructor (props) {
    super(props);
    const shipping = _merge(emptyShipping(), this.props.customer.shipping || {});
    if (!shipping.country) shipping.country = defaultCountry;
    if (shipping.first_name === "" && this.props.first_name !== "") shipping.first_name = this.props.first_name;
    if (shipping.last_name === "" && this.props.last_name !== "") shipping.last_name = this.props.last_name;
    const billing = _merge(emptyBilling(), this.props.customer.billing || {});
    if (!billing.country) billing.country = defaultCountry;
    if (billing.first_name === "" && this.props.first_name !== "") billing.first_name = this.props.first_name;
    if (billing.last_name === "" && this.props.last_name !== "") billing.last_name = this.props.last_name;
    billing.phone = this.props.phone;

    this.state = {
      shipping,
      shipping_showAddress2: !!(shipping && shipping.address_2),

      billingAddressSame: !isDiffAddresses(shipping, billing),
      billing_showAddress2: !!(billing && billing.address_2),
      billing,

      saving: false,
      email: this.props.email,
      freeShipping: true,
      error_phone: ""
    };
  }
  componentDidMount () {
    this.getRouteQuote().then(() => {
      console.log("getRouteQuote created");
    });
  }
  getRouteQuote = async () => {
    if (!window.routeapp) await loadRouteJs();
    let total
    const cartProducts = readLocalCart();
    const subscriptionProducts = cartProducts.filter(p => p.type === "subscription");
    total = formatAmount(applyTaxToTotal(applyCouponToCartProducts(cartProducts, this.props.coupon).total,this.props.customer)+this.props.shippingFee);
    
    var that = this.props;
    if(subscriptionProducts.length > 0 && subscriptionProducts.length < cartProducts.length){
      var total_sub = formatAmount(applyTaxToTotal(applyCouponToCartProducts(subscriptionProducts, this.props.coupon).total,this.props.customer)+this.props.shippingFee);
      
      window.routeapp.get_quote("5de71692-9880-4993-9401-54c28a0a6c3e", total_sub, "USD", function(insurance_details){
        
        // TODO: If insurance_details.insurance_selected response is true, add the Route insurance to order total
        that.routePackageProtectionSubscriptionCallback(insurance_details.insurance_price)

        window.routeapp.get_quote("5de71692-9880-4993-9401-54c28a0a6c3e", total, "USD", function(insurance_details_total){
          
          // TODO: If insurance_details.insurance_selected response is true, add the Route insurance to order total
          that.routePackageProtectionCallback(insurance_details_total.insurance_price)
  
        });
  
      });
    }else{
      window.routeapp.get_quote("5de71692-9880-4993-9401-54c28a0a6c3e", total, "USD", function(insurance_details){
        
        // TODO: If insurance_details.insurance_selected response is true, add the Route insurance to order total
        that.routePackageProtectionCallback(insurance_details.insurance_price)
        if(subscriptionProducts.length > 0 && subscriptionProducts.length === cartProducts.length){
          that.routePackageProtectionSubscriptionCallback(insurance_details.insurance_price)
        }

      });
    }
    window.routeapp.on_insured_change(function(insurance_details){
      if (insurance_details.insurance_selected) {
          
          // TODO: Add the Route insurance to order total and/or to cart
          that.routePackageProtectionCallback(insurance_details.insurance_price)
      }
      else {
        // will fire on widget checkbox "click" deselect
        // TODO: Remove Route insurance from total and/or cart
        that.routePackageProtectionCallback(0)
      }
  });
  }

  resetErrors = () => {
    this.setState({
      error_phone: ""
    });
  };
  handleChangeEmail = e => {
    this.setState({email: e.target.value});
  }

  handleChangeShipping = e => {
    const shipping = update(this.state.shipping, { [e.target.name]: { $set: e.target.value } });
    this.setState({ shipping });
  };

  handleChangeBilling = e => {
    const billing = update(this.state.billing, { [e.target.name]: { $set: e.target.value } });
    this.setState({ billing });
  };

  selectShippingCountry = val => {
    const shipping = update(this.state.shipping, { country: { $set: val } });
    this.setState({ shipping });
    this.props.shippingFeeCallback(val);
  };

  selectShippingRegion = val => {
    const shipping = update(this.state.shipping, { state: { $set: val } });
    this.setState({ shipping });
  };

  selectBillingCountry = val => {
    const billing = update(this.state.billing, { country: { $set: val } });
    this.setState({ billing });
  };

  selectBillingRegion = val => {
    const billing = update(this.state.billing, { state: { $set: val } });
    this.setState({ billing });
  };

  handleFreeShipping = e => {
    this.setState({ freeShipping: e.target.checked })
  };
  billingAddressSameChange = e => {
    this.setState({ billingAddressSame: e.target.checked }, () => {
      if (this.state.billingAddressSame) {
        // copy values from shipping to billing
        const billing = {
          first_name: this.state.shipping.first_name,
          last_name: this.state.shipping.last_name,
          company: "",
          address_1: this.state.shipping.address_1,
          address_2: this.state.shipping.address_2,
          city: this.state.shipping.city,
          postcode: this.state.shipping.postcode,
          country: this.state.shipping.country || defaultCountry,
          state: this.state.shipping.state,
          email: "",
          phone: this.state.billing.phone
        };
        this.setState({ billing });
      }
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.resetErrors();
    let phone = true;
    if(this.props.isGuest){
      phone = validatePhone(this.state.billing.phone);
    }
    let success, shipping, billing;
    if(phone){
      this.setState({phone: phone});
      try {
        this.setState({ saving: true });
        [shipping, billing] = await Promise.all([
          this.saveShippingAddress(),
          this.saveBillingAddress()
        ]);
        if (this.props.isGuest) {
          billing.phone = phone;
          if(this.state.email !== ""){
            billing.email = this.state.email;
          }
        }
        success = true;
      } catch (e) {
        throw e;
      } finally {
        this.setState({ saving: false });
      }
    }else{
      this.setState({ error_phone: "Invalid phone number" });
    }
    success && this.props.callback({ shipping, billing });
  };

  saveShippingAddress = async () => {
    let shipping = this.state.shipping;
    const changed = isDiffAddresses(this.props.customer.shipping, shipping);
    if (!this.props.isGuest && changed) shipping = await api.account.updateShipping(shipping);
    return shipping;
  };

  saveBillingAddress = async () => {
    let billing = this.state.billingAddressSame ? this.state.shipping : this.state.billing;
    billing.phone = this.state.billing.phone;
    const changed = isDiffAddresses(this.props.customer.billing, billing);
    if (!this.props.isGuest && changed) {
      const payload = billing;
      delete payload.email;
      //delete payload.phone;
      billing = await api.account.updateBilling(billing);
    }
    return billing;
  };


  render () {
    return (
      <div className={`container spacing${this.props.className ? ` ${this.props.className}` : ""}`}>

        <form onSubmit={this.handleSubmit} className="form">

          {/* shipping fieldset */}
          <fieldset className="mb-3">
            {this.props.isGuest ?
              <div className="field is-horizontal">
                <div className="field-body">
                  <TextField label="Email" name="email" type="email" value={this.state.email} onChange={this.handleChangeEmail}/>
                  <TextField label="Phone" name="phone" error={this.state.error_phone} value={this.state.billing.phone} onChange={this.handleChangeBilling}/>
                </div>
              </div>
              :
              false
            }

            <h4 className="fieldset-title">Shipping address</h4>
            <div className="field is-horizontal">
              <div className="field-body">
                <TextField label="First Name:" name="first_name" value={this.state.shipping.first_name} onChange={this.handleChangeShipping} />
                <TextField label="Last Name:" name="last_name" value={this.state.shipping.last_name} onChange={this.handleChangeShipping} />
              </div>
            </div>

            <TextField label="Shipping Address:" name="address_1" value={this.state.shipping.address_1} onChange={this.handleChangeShipping} />

            {this.state.shipping_showAddress2 ?
              <TextField label="Shipping Address 2:" name="address_2" value={this.state.shipping.address_2} onChange={this.handleChangeShipping} />
              :
              <button type="button" onClick={() => this.setState({ shipping_showAddress2: !this.state.shipping_showAddress2 })}
                className="select-button is-primary mb-3">+ ADD ADDITIONAL INFO</button>
            }

            <div className="field is-horizontal">
              <div className="field-body">
                <div className={`field${this.state.shipping.country ? " has-value" : ""}`}>
                  <div className="select is-fullwidth">
                    <CountryDropdown
                      required
                      name="country"
                      valueType="short"
                      value={this.state.shipping.country}
                      onChange={this.selectShippingCountry} />
                  </div>
                </div>
                <div className={`field${this.state.shipping.state ? " has-value" : ""}`}>
                  <div className="select is-fullwidth">
                    <RegionDropdown
                      required
                      name="state"
                      valueType="short"
                      country={this.state.shipping.country}
                      countryValueType="short"
                      value={this.state.shipping.state}
                      onChange={this.selectShippingRegion} />
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-body">
                <TextField label="City:" name="city" value={this.state.shipping.city} onChange={this.handleChangeShipping} />
                <TextField label="Zip Code:" name="postcode" value={this.state.shipping.postcode} onChange={this.handleChangeShipping} />
              </div>
            </div>

          </fieldset>

          <div className="field">
            <div className="control">
              <label className="checkbox is-size-6 billing-address-same-checkbox m-0">
                <input type="checkbox" checked={this.state.billingAddressSame} onChange={this.billingAddressSameChange} />
                Billing address is same as shipping address
              </label>
            </div>
          </div>

          {/* billing fieldset */}
          {!this.state.billingAddressSame &&
          <fieldset className="mb-3">
            <h4 className="fieldset-title">Billing address</h4>
            <div className="field is-horizontal">
              <div className="field-body">
                <TextField label="First Name:" name="first_name" value={this.state.billing.first_name} onChange={this.handleChangeBilling} />
                <TextField label="Last Name:" name="last_name" value={this.state.billing.last_name} onChange={this.handleChangeBilling} />
              </div>
            </div>

            <TextField label="Billing Address:" name="address_1" value={this.state.billing.address_1} onChange={this.handleChangeBilling} />

            {this.state.billing_showAddress2 ?
              <TextField label="Billing Address 2:" name="address_2" value={this.state.billing.address_2} onChange={this.handleChangeBilling} />
              :
              <button type="button" onClick={() => this.setState({ billing_showAddress2: !this.state.billing_showAddress2 })}
                className="select-button is-primary">+ ADD ADDITIONAL INFO</button>
            }

            <div className="field is-horizontal">
              <div className="field-body">
                <div className={`field${this.state.billing.country ? " has-value" : ""}`}>
                  <div className="select is-fullwidth">
                    <CountryDropdown
                      required
                      name="country"
                      valueType="short"
                      value={this.state.billing.country}
                      onChange={this.selectBillingCountry} />
                  </div>
                </div>
                <div className={`field${this.state.billing.state ? " has-value" : ""}`}>
                  <div className="select is-fullwidth">
                    <RegionDropdown
                      required
                      name="state"
                      valueType="short"
                      country={this.state.billing.country}
                      countryValueType="short"
                      value={this.state.billing.state}
                      onChange={this.selectBillingRegion} />
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-body">
                <TextField label="City:" name="city" value={this.state.billing.city} onChange={this.handleChangeBilling} />
                <TextField label="Zip Code:" name="postcode" value={this.state.billing.postcode} onChange={this.handleChangeBilling} />
              </div>
            </div>
          </fieldset>}
          <div className="field">
            <div className="control">
             
              <label className="checkbox is-size-6 free-shipping-checkbox m-0">
                <input type="checkbox" checked readOnly/>
                { this.props.shippingFee === 0 ? "1-3 DAY PRIORITY SHIPPING | FREE (US ONLY)":"Flat Rate Shipping | $"+this.props.shippingFee }
              </label>
            </div>
          </div>
          <div id="RouteWidget" data-default-checked="true"></div>
          <button className={`button is-primary is-fullwidth is-medium${this.state.saving ? " is-loading" : ""}`} type="submit">
            Continue
          </button>

        </form>
      </div>
    );
  }

}

ShippingAndBilling.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
